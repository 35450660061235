import { useEffect } from "react";
import { useRouter } from "next/router";
import { kybPathnames, useKYBHeader } from "./header";
import LoadingSpinner from "../../components/Loading";
import { localUserStatusBoolean, useGetAuth } from "../../contexts/AuthContext";

// REGISTER EVENTS
export const LOGIN_TO_SIGN_IN_EXPORT_EVENT = "register_to_login";
export const HIT_REGISTER_EVENT = "register";
export const RESEND_REGISTER_EVENT = "register_resend_email";
export const DOWNLOAD_AGREEMENT_EVENT = "register_download_agreement";

// KYB EVENTS
export const START_KYB_EVENT = "kyb_start";
export const SUBMIT_BUSINESS_DETAILS_EVENT = "business_kyb_details_submit";
export const SUBMIT_MAJOR_SHAREHOLDER_EVENT = "ubo_major_details";
export const AGREE_TO_BO_DECLARATION_EVENT = "kyb_agreed_ubo_declaration";
export const SUBMIT_SUPPORTING_DOCUMENTS_EVENT = "kyb_submits_supporting_docs";
export const COMPLETED_KYB_PROCESS_EVENT = "completed_kyb_process";
export const KYB_VERIFIED_EVENT = "completed_kyb";

// KYB DOCUMENT CATEGORY EVENTS
export const UPLOAD_POWER_OF_ATTORNEY_EVENT = "kyb_docs_poa_upload";
export const DELETE_POWER_OF_ATTORNEY_EVENT = "kyb_docs_poa_deletes";
export const UPLOAD_DEED_OF_ESTABLISHMENT_EVENT =
  "kyb_docs_deed_establishment_upload";
export const DELETE_DEED_OF_ESTABLISHMENT_EVENT =
  "kyb_docs_deed_establishment_deletes";
export const UPLOAD_LATEST_DEED_OF_AMENDMENT_EVENT =
  "kyb_docs_latest_deed_upload";
export const DELETE_LATEST_DEED_OF_AMENDMENT_EVENT =
  "kyb_docs_latest_deed_deletes";
export const UPLOAD_COMPANY_TAX_ID_EVENT = "kyb_docs_company_tax_id_upload";
export const DELETE_COMPANY_TAX_ID_EVENT = "kyb_docs_company_tax_id_deletes";
export const UPLOAD_BUSINESS_PERMIT_EVENT = "kyb_docs_business_permit_upload";
export const DELETE_BUSINESS_PERMIT_EVENT = "kyb_docs_business_permit_deletes";

export const KYBRedirectorScreen = () => {
  const {
    isReview,
    loading,
    isStructureFilled,
    isRepresentativeFilled,
    isBankFilled,
    isDetailsFilled,
  } = useKYBHeader();

  const { user } = useGetAuth();
  const { isWaitingVerification, isPending } = localUserStatusBoolean(user);

  const { push } = useRouter();

  const {
    reviewPathname,
    supportingPathname,
    structurePathname,
    representativePathname,
    bankPathname,
    onboardPathname,
    onReviewPathname,
    onReviewPendingPathname,
  } = kybPathnames();

  useEffect(() => {
    if (loading) return;
    if (isPending) return push(onReviewPendingPathname);
    if (isWaitingVerification) return push(onReviewPathname);
    if (isReview) return push(reviewPathname);
    if (isStructureFilled) return push(supportingPathname);
    if (isRepresentativeFilled) return push(structurePathname);
    if (isBankFilled) return push(representativePathname);
    if (isDetailsFilled) return push(bankPathname);
    push(onboardPathname);
  }, [loading]);

  return <LoadingSpinner />;
};
