export const typeArrayKYB = [
  {
    // label: "PT (Limited Liability Company)",
    label: "PT (PERSEROAN TERBATAS)",
    value: "pt parent",
    obj: {
      title: "Type of Shares",
      array: [
        {
          label: "State-owned enterprises (BUMN/PERSERO/PERUM)",
          value: "Government Body",
          help: "Shares owned by the state",
        },
        {
          label: "Public company (TBK)",
          value: "Public Company",
          help: "Shares listed on the stock exchange",
        },
        {
          label: "Private (PT) ",
          value: "Private Limited Company",
          help: "Shares owned by individuals and / or private entities",
        },
      ],
    },
  },
  {
    // label: "Individual Limited Company",
    label: "PT PERORANGAN (PERSEROAN PERORANGAN)",
    value: "Individual Limited Company",
  },
  {
    // label: "CV (Commanditaire Vennootschap)",
    label: "CV (PERSEKUTUAN KOMANDITER)",
    value: "Partnership",
  },
  {
    // label: "PD/UD (Sole Proprietorship)",
    label: "PU/UD (PERUSAHAAN DAGANG/USAHA DAGANG)",
    value: "Sole Trader",
  },
  {
    // label: "Cooperatives",
    label: "KOPERASI",
    value: "Cooperatives",
  },
  {
    // label: "Association",
    label: "PERUSAHAAN ASOSIASI",
    value: "Association",
  },
  {
    // label: "Foundation",
    label: "YAYASAN",
    value: "Foundation",
  },
];
